<script>
import { Modal } from "bootstrap";
import CurrencyInput from "@/components/CurrencyInput";

export default {
  page: {},
  components: { CurrencyInput },
  data() {
    return {
      currenciesArray: [],
      matchedAccounts: [],
      addPayAccounts: [{}],
      disposed_asset: {},
      add_modal: null,
      dispose_modal: null,
      re_evalute_modal: null,
      edit_modal: null,
      register_modal: null,
      remaining_value: 0,
      branches: [],
      branch_sites: [],
      newAsset: {},
      reEvaluationAsset: {},
      registerAsset: {},
      // end global data
      fixedAssets: [],
      cash_accounts: [],
      typesOptions: [],
      cashAccounts: [],
      addAsset: [{}],
      // totalValue: 0,
      page: 1,
      limit: 20,
      tot_pages: 0,
      searchBudget: null,
      isDepreciable: false,
      isRegisterDepreciable: false,
      classes: [],
      subclasses: [],
      SearchMode: false,
      search: "",
      // payment process data
      isOnCredit: true,
      selectedPaymentType: "",
      paymentTypes: [
        { id: 1, name: "مديونية", name_en: "Pay On Credit" },
        { id: 2, name: "دفع جزئي", name_en: "Partial Payment" },
        { id: 3, name: "دفع كامل", name_en: "Full Payment" },
      ],
      previous_payable: 0,
      prepayment: 0,
      due_date: null,
      is_du_time: false,
      // agent auto complete data :
      agents: [],
      purchase_item_type: {},
      reEvaluate_value: []
      // appprices:[]
    };
  },
  computed: {
    totalDisposalAccountsValue() {

      return this.addAsset
        .filter((account) => account.level_three_chart_of_account_id && account.value)
        .reduce((sum, account) => sum + account.value, 0);
    },
    validateFullPayment(){
      return this.selectedPaymentType<3 || this.totalAddPayAccounts >= parseFloat(this.newAsset?.initial_book_value)
    },
    totalAddPayAccounts(){
      return this.addPayAccounts.filter(account=>account.value)
          .reduce((sum,account)=>{
            console.log("this.newAsset?.currency?.id",this.newAsset?.currency?.id,"sum",sum,"account",account)
            if(this.newAsset?.currency?.id != account.cash_account.currency_id && this.newAsset?.currency?.is_functional){
              sum += parseFloat(account.value) * parseFloat(account.cash_account.current_rate)
            }
            else if(this.newAsset?.currency?.id !=account.cash_account.currency_id && account.cash_account.is_functional){
              sum += parseFloat(account.value) / parseFloat(this.newAsset?.currency?.current_rate)
            }
            else if(this.newAsset?.currency?.id != account.cash_account.currency_id){
              sum += parseFloat(account.value) * parseFloat(  this.newAsset?.currency?.current_rate) / parseFloat(account.cash_account.current_rate)
            }
            else{
              sum += parseFloat(account.value)
            }
            return sum
          },0)
    }
  },
  methods: {
    getPurchaseConfigurations() {
      this.http
        .get("purchases/configurations")
        .then((res) => {
          this.vatValue = parseFloat(res.vat_value.value);
          this.cash_accounts = res.cash_accounts;
          this.inventories = res.inventories;
          this.landedCostTypes = res.landed_cost_types;
        });
    },
    accountSearch(query){
      this.matchedAccounts = this.cash_accounts.filter(account=>
          (
              account.name.toLowerCase().includes(query.toLowerCase()) ||
              account.name_en.toLowerCase().includes(query.toLowerCase())
          ) &&
          !this.addPayAccounts.some(payAccount=>payAccount?.cash_account?.cash_account_id==account.cash_account_id)

      )
      console.log("this.matchedAccounts",this.matchedAccounts)
      console.log("this.matchedAccount",this.addPayAccounts)
      console.log("this.inid",this.addPayAccounts[0].cash_account.id)
    },
    showLabel(account){
      console.log("ACCOUNT",account)
      return (this.$i18n.locale == "ar"
          ? account?.name
          : account?.name_en) + " - " +
              this.processCurrency(account,account.balance)
    },
    searchFixedAssets() {
      this.SearchMode = true;
      this.tot_pages = 0;
      this.http
        .post("fixed-assets/search", { search: this.search })
        .then((res) => {
          this.fixedAssets = res.data;
        });
    },
    cancelFixedAssetsSearchMode() {
      this.search = "";
      this.SearchMode = false;
      this.getFixedAssetsPagination(this.page);
    },
    registerFixedAsset() {
      console.log(this.registerAsset);
      this.typesOptions.forEach((type) => {
        if (type.id == this.registerAsset.fixed_asset_type_id) {
          this.registerAsset.type_name = type.name_en;
        }
      });
      delete this.registerAsset.fixed_asset_type;

      this.http
        .post("transactions/register-assets", {
          register: this.registerAsset,
        })
        .then((res) => {
          if (res.status) {

            this.registerAsset = {};
            this.getFixedAssetsPagination(this.page);
            this.register_modal.hide()
          }
        });
    },
    addFixedAsset() {
      console.log("this.addPayAccounts",this.addPayAccounts)
      this.typesOptions.forEach((type) => {
        if (type.id == this.newAsset.fixed_asset_type_id) {
          this.newAsset.type_name = type.name_en;
        }
      });

      delete this.newAsset.fixed_asset_type;
      this.http
        .post("transactions/fixed-assets", {
          asset_data: this.newAsset,
          accounts: this.addPayAccounts,
          selectedPaymentType: this.selectedPaymentType,
        })
        .then((res) => {
          if (res.status) {
            this.newAsset = {};
            this.addAsset = [{}];
            this.getFixedAssetsPagination(this.page);
            this.add_modal.hide();
            // this.totalValue = 0;
          }
        });
    },
    preEditFixedAsset(fixedAsset) {
      this.newAsset = JSON.parse(JSON.stringify(fixedAsset));
      this.newAsset.depreciable = this.newAsset.depreciable ? true : false;
    },
    preAddAsset() {
      this.getAccounts();
      this.add_modal.show();
    },
    editFixedAsset() {
      this.http
        .put("fixed-assets", this.newAsset.id, this.newAsset)
        .then((res) => {
          if (res.status) {

            this.getFixedAssetsPagination(this.page);
            this.edit_modal.hide()
            this.$swal.fire(this.$t("messages.success"));

          }
        });
    },
    deleteFixedAsset(fixedAsset) {
      var data = {
        title: "popups.delete",
        msg: "popups.delmessage",
      };
      this.popup.confirm(data).then((resp) => {
        if (resp) {
          this.http.delete("fixed-assets", fixedAsset.id).then(() => {
            this.getFixedAssetsPagination(this.page);
          });
        }
      });
    },
    getFixedAssetsPagination(page) {
      this.http
        .post("fixed-assets/pagination", {
          limit: this.limit,
          page: page,
        })
        .then((res) => {
          this.page = page;
          this.tot_pages = Math.ceil(res.tot / this.limit);
          console.log(this.tot_pages);
          this.fixedAssets = res.data;
          console.log("this.fixedAssets", this.fixedAssets);
        });
    },
    getAccounts() {
      this.http.get("transactions/cash-accounts", {}).then((resp) => {
        this.cashAccounts = resp.data;
      });
    },
    doDispose() {

      this.http
        .post("fixed-assets/disposal", {
          id: this.disposed_asset.id,
          name: this.disposed_asset?.name,
          name_en: this.disposed_asset?.name_en,
          remaining_value: this.remaining_value,
          fixed_asset_type_id: this.disposed_asset.fixed_asset_type_id,
          accounts: this.addAsset,
        })
        .then((res) => {
          if (res.status) {
            this.dispose_modal.hide();
            this.getFixedAssetsPagination(this.page);
            this.$swal.fire(this.$t("messages.success"));

          }
        });
    },

    getFixedAssetTypesOptions() {
      this.http.get("fixed-assets/types").then((resp) => {
        this.typesOptions = resp.data;
      });
    },
    sellFixedAsset(asset) {
      this.disposed_asset = asset;
      console.log('step 209 :', asset)
      this.http
        .post("fixed-assets/process-disposal", { id: asset.id })
        .then((res) => {
          console.log(res.status);
          this.remaining_value = res.data?.remaining_value;
          this.addAsset = [{}];
          this.getAccounts();
          this.dispose_modal.show();
        });
    },

    selectFixedAssetType(type) {
      console.log("step 253 :", type);
      this.newAsset.fixed_asset_type_id = type.id;
      this.newAsset.level_three_chart_of_account_id =
        type.level_three_chart_of_account_id;
    },
    selectFixedAssetTypeRegister(type) {
      this.registerAsset.fixed_asset_type_id = type.id;
      this.registerAsset.level_three_chart_of_account_id =
        type.level_three_chart_of_account_id;
    },

    startPaymentProcess() {
      if (this.selectedPaymentType == 1) {
        this.isOnCredit = true;
        this.addAsset = [{}];
      } else {
        this.isOnCredit = false;
      }
      if (this.selectedPaymentType > 2) {
        this.is_du_time = true;
      } else {
        this.is_du_time = false;
      }
    },
    // agent auto complete
    agentSearch(search) {
      this.http
        .post("purchases-agents/fixed-assets-search", {
          search,
          type:this.newAsset?.supplier_type
        })
        .then((res) => {
          if (res.status) {
            this.agents = res.data;
          }
        });
    },
    selectAgent(agent) {
      this.newAsset.agent_id = agent.id;
      this.http
          .post("purchases-agents/select-fixed-assets-supplier", {
            id: agent.id,
          })
          .then((res) => {
            if (res.status) {
              this.currenciesArray = res.currencies;
              this.prepayment = res.data.prepayment;
              this.previous_payable = res.data.previous_payable;
              // if the functional currency is the supplier currency make it selected
              if(this.currenciesArray?.length == 1)
                this.newAsset.currency = this.purchase_order.currenciesArray[0]
            }
          });
    },
    showSupplierLabel(one) {
      return one.name;
    },
    getPurchasesOrderType() {
      this.http
        .post("purchase-orders/get-purchase-item-type",  {code: "333"} )
        .then((res) => {
          this.purchase_item_type = res.data;
        });
    },
    preReEvaluteAsset(fixedAsset) {
      this.reEvaluationAsset = JSON.parse(JSON.stringify(fixedAsset));
    },
    reEvaluteAsset() {
      console.log(this.reEvaluationAsset);
      console.log(this.reEvaluate_value);
      this.http
        .post("fixed-assets/evaluate_asset",  {fixed_asset_id : this.reEvaluationAsset.id , value : this.reEvaluate_value.value} )
        .then((res) => {
          if (res.status) {
          this.getFixedAssetsPagination(this.page);
          this.re_evalute_modal.hide();
          }
        });
    },
  },
  // end

  mounted() {
    this.selectedPaymentType = this.paymentTypes[0]?.id;
    // const current_user = JSON.parse(localStorage.pos_station);
    // this.pos_station_id = current_user.pos_station_id;
    this.getFixedAssetsPagination(1);
    this.getFixedAssetTypesOptions();
    this.getAccounts();
    this.getPurchasesOrderType();
    this.getPurchaseConfigurations();
    this.re_evalute_modal = new Modal("#reEvaluteModal");
    this.dispose_modal = new Modal("#disposeModal");
    this.add_modal = new Modal("#addModal");
    this.edit_modal = new Modal('#editModal');
    this.register_modal = new Modal('#registerModal')
  },
};
</script>

<template>
  <div>
    <div class="row">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-6">
              <div class="search-box chat-search-box w-50">
                <div class="position-relative">
                  <input @keyup="searchFixedAssets()" type="text" class="form-control fa-lg text-light"
                    :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" :placeholder="$t('popups.search')" v-model="search"
                    style="background-color: #2a3042 !important" />
                </div>
              </div>
            </div>
            <div class="col-6" style="display: flex; justify-content: flex-end">
              <button class="btn-close text-light" v-if="SearchMode" @click="cancelFixedAssetsSearchMode"></button>

              <button type="button" data-bs-toggle="modal" data-bs-target="#registerModal"
                class="btn btn-secondary float-end mb-4 mx-2">
                {{ $t("fixed_assets.register") }}
              </button>
              <button type="button" @click="preAddAsset()" class="btn btn-primary float-end mb-4">
                {{ $t("fixed_assets.buy") }}
              </button>
            </div>
          </div>
          <div class="ant-table-content table-responsive">
            <table style="table-layout: auto" class="table table-centered table-nowrap table-striped table-hover align-middle">
              <thead class="ant-table-thead">
                <tr class="text-light" style="background-color: #2a3042 !important">
                  <th class="ant-table-cell text-start ">#</th>
                  <th class="ant-table-cell text-start "> <span class="ant-table-column-title">{{
                      $t("fixed_assets.label") }}
                    </span>
                  </th>
                  <th class="ant-table-cell text-start ">
                    <span class="ant-table-column-title">{{
                      $t("fixed_assets.label_en") }}
                    </span>
                  </th>
                  <th class="ant-table-cell text-start ">
                    <span class="ant-table-column-title">{{ $t("fixed_assets.type")
                      }}
                    </span>
                  </th>
                  <th class="ant-table-cell text-start ">
                    <span class="ant-table-column-title">{{
                      $t("fixed_assets.initial") }}
                    </span>
                  </th>
                  <!-- <th class="ant-table-cell text-start ">
                    <span class="ant-table-column-title">{{
                        $t("fixed_assets.current") }}
                    </span>
                  </th> -->
                  <th class="ant-table-cell text-start ">
                    <span class="ant-table-column-title">{{
                      $t("fixed_assets.depreciable") }}
                    </span>
                  </th>
                  <th class="ant-table-cell text-start ">
                    <span class="ant-table-column-title">{{
                      $t("fixed_assets.under_warranty") }}
                    </span>
                  </th>
                  <th class="ant-table-cell text-start ">
                    <span class="ant-table-column-title">{{
                      $t("fixed_assets.warranty_exp") }}
                    </span>
                  </th>
                  <th class="ant-table-cell text-start ">
                    {{ $t("fixed_assets.installation_date") }}
                  </th>

                  <th class="ant-table-cell text-start ">
                    <span class="ant-table-column-title">{{
                      $t("fixed_assets.comment") }}
                    </span>
                  </th>
                  <th class="ant-table-cell text-start "><span class="ant-table-column-title">{{
                      $t("fixed_assets.created") }}
                    </span>
                  </th>
                  <th class="ant-table-cell text-start "><span class="ant-table-column-title">{{
                      $t("fixed_assets.operations") }}
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody class="ant-table-tbody">
                <tr :title="$i18n.locale == 'ar'
                      ? fixedAsset?.name
                      : fixedAsset?.name_en
                      " v-for="(fixedAsset, index) in fixedAssets" :key="index"
                  class="ant-table-row ant-table-row-level-0">
                  <td>{{ index + 1 }}</td>
                  <td class="ant-table-cell text-start">
                    {{ fixedAsset?.name }}
                  </td>
                  <td class="ant-table-cell text-start">
                    {{ fixedAsset?.name_en }}
                  </td>

                  <td class="ant-table-cell text-start">
                    {{
                      $i18n.locale == "ar"
                        ? fixedAsset?.type
                        : fixedAsset?.type_en
                    }}
                  </td>
                  <td class="ant-table-cell text-start">{{ parseFloat(fixedAsset?.initial_book_value).toLocaleString() }}</td>
                  <!-- <td class="ant-table-cell text-start">
                    {{
                      fixedAsset?.depreciable
                        ? $t("fixed_assets.yes")
                        : $t("fixed_assets.no")
                    }}
                  </td> -->

                  <td class="ant-table-cell text-start">
                    {{
                      fixedAsset?.depreciable
                        ? $t("fixed_assets.yes")
                        : $t("fixed_assets.no")
                    }}
                  </td>
                  <td class="ant-table-cell text-start">
                    {{
                        fixedAsset?.under_warranty
                          ? $t("fixed_assets.yes")
                          : $t("fixed_assets.no")
                    }}
                  </td>
                  <td class="ant-table-cell text-start">{{ fixedAsset?.warranty_exp }}</td>
                  <td class="ant-table-cell text-start">{{ fixedAsset?.installation_date }}</td>
                  <td class="ant-table-cell text-start">{{ fixedAsset?.comment }}</td>
                  <td class="ant-table-cell text-start">{{ new Date(fixedAsset?.created).toLocaleString('en-GB') }}</td>
                  <td class="ant-table-cell text-start">

<!--                    <a style="margin-inline-end: 4px" class="btn btn-primary btn-md"-->
<!--                      @click="preReEvaluteAsset(fixedAsset)" data-bs-toggle="modal" data-bs-target="#reEvaluteModal"-->
<!--                      role="button">{{ $t("fixed_assets.re_Evalute_Asset") }}</a>-->
                    <a style="margin-inline-end: 4px" class="btn btn-primary btn-md" @click="sellFixedAsset(fixedAsset)"
                      role="button">{{ $t("fixed_assets.disposal") }}</a>
                    <a style="margin-inline-end: 4px" class="btn btn-primary btn-md"
                      @click="preEditFixedAsset(fixedAsset)" data-bs-toggle="modal" data-bs-target="#editModal"
                      role="button">{{ $t("popups.edit") }}</a>
                    <a class="btn btn-danger btn-md" href="javascript: void(0);" role="button"
                      @click="deleteFixedAsset(fixedAsset)">{{ $t("popups.delete") }}</a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <!-- end  table -->

    <!--end-->
    <!--Start Add Modal-->
    <div class="modal fade" id="addModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true"
      :dir="$i18n.locale == 'ar' ? 'rtl' : ''">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <form @submit.prevent="addFixedAsset()">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                {{ $t("popups.add") }}
              </h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                style="margin: 0"></button>
            </div>

            <div class="modal-body">
              <div class="row">
                <div class="col-12 col-md-6">
                  <div class="form-group">
                    <label for="asset_id">{{ $t("fixed_assets.type") }}</label>

                    <select v-model="newAsset.fixed_asset_type" @change="
                      selectFixedAssetType(newAsset.fixed_asset_type)
                      " class="form-select" id="account_id" :placeholder="$t('fixed_assets.type')">
                      <option v-for="options in typesOptions" :key="options" :value="options">
                        {{
                      $i18n.locale == "ar"
                        ? options?.name
                        : options?.name_en
                    }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-12 col-md-6">
                  <div class="form-group">
                    <label>{{ $t("purchases.supplier") }}</label>
                    <select v-model="newAsset.supplier_type" class="form-select mb-2">
                      <option value="export">{{$t("purchases.export_process")}}</option>
                      <option value="import">{{$t("purchases.import_process")}}</option>
                    </select>
                  </div>
                </div>
                <div class="col-12 col-md-6" v-if="newAsset.supplier_type">
                  <div class="form-group">
                    <AutoComplete

                        v-model="newAsset.agent"
                        :suggestions="agents"
                        :complete-on-focus="true"
                        @complete="agentSearch($event.query)"
                        @item-select="selectAgent(newAsset.agent)"
                        :optionLabel="showSupplierLabel" class="w-100" :input-class="'form-control w-100'" />
                    <table class="balance-table">
                      <tr v-if="previous_payable != 0">
                        <td>
                          <span class="fw-bold">
                            {{ $t("purchases.you_owed_debt_to_supplier") }} :
                          </span>
                        </td>
                        <td>
                          {{ parseFloat(previous_payable).toLocaleString() }}
                        </td>
                      </tr>
                      <tr v-if="prepayment != 0">
                        <td>
                          <span class="fw-bold">
                            {{
                              $t("purchases.you_have_prepayment_to_supplier")
                            }}
                            :
                          </span>
                        </td>
                        <td>
                          {{ parseFloat(prepayment).toLocaleString() }}
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>

                <!-- Currency -->
                <div class="col-12 col-md-6" v-if="newAsset?.agent?.id">
                  <labele class="form-label">
                    {{ $t("popups.currency") }}
                  </labele>
                  <select v-model="newAsset.currency" :disabled="currenciesArray?.length == 1" class="form-select">
                    <option :value="currency" v-for="currency in currenciesArray" :key="currency">
                      {{
                        $i18n.locale == "ar"
                            ? currency?.name
                            : currency?.name_en
                      }}
                    </option>
                  </select>

                </div>

                <div class="col-12 col-md-6">
                  <div class="form-group">
                    <label for="asset_id">{{ $t("fixed_assets.label") }}</label>
                    <input required v-model="newAsset.name" type="text" class="form-control" />
                  </div>
                </div>
                <div class="col-12 col-md-6">
                  <div class="form-group">
                    <label for="asset_id">{{
                      $t("fixed_assets.label_en")
                    }}</label>
                    <input required v-model="newAsset.name_en" type="text" class="form-control" />
                  </div>
                </div>

                <div class="col-12 col-md-6">
                  <div class="form-group">
                    <label for="asset_id">{{
                      $t("fixed_assets.initial")
                    }}</label>
                    <currency-input v-model="newAsset.initial_book_value" class="form-control" />
                  </div>
                </div>


                <div class="col-12 col-md-6" v-if="newAsset?.fixed_asset_type?.depreciable">
                  <div class="form-group">
                    <label for="asset_id">{{
                      $t("fixed_assets.salvage_value")
                    }}</label>
                    <currency-input v-model="newAsset.salvage_value" class="form-control" />
                  </div>
                </div>
                <div class="col-12 col-md-6" v-if="newAsset?.fixed_asset_type?.depreciable">
                  <div class="form-group">
                    <label for="asset_id">{{
                      $t("fixed_assets.useful_life_years")
                    }}</label>
                    <input required v-model="newAsset.useful_life_years" type="number" class="form-control" />
                  </div>
                </div>
                <!-- d -->
                <div class="col-12 col-md-6">
                  <div class="form-group">
                    <label for="asset_id">{{
                      $t("fixed_assets.installation_date")
                    }}</label>
                    <input required v-model="newAsset.installation_date" type="date" class="form-control" />
                  </div>
                </div>

                <div class="col-12 col-md-6">
                  <div class="form-group form-check">
                    <input type="checkbox" v-model="newAsset.under_warranty" id="under_warranty"
                           class="form-check-input" />
                    <label class="form-check-label" for="under_warranty">{{
                        $t("fixed_assets.under_warranty")
                      }}</label>
                  </div>
                  <div class="form-group" v-if="newAsset.under_warranty">
                    <label for="asset_id">{{
                      $t("fixed_assets.warranty_exp")
                    }}</label>
                    <input v-model="newAsset.warranty_exp" type="date" class="form-control" />
                  </div>
                </div>


                <div class="col-12 col-md-6">
                  <div class="form-group">
                    <label for="asset_id">{{
                      $t("fixed_assets.comment")
                    }}</label>
                    <textarea v-model="newAsset.comment" class="form-control"></textarea>
                  </div>
                </div>

                  <hr v-if="newAsset?.currency && newAsset?.agent_id" style="visibility:hidden">
                <hr v-if="newAsset?.currency && newAsset?.agent_id">
                <div v-if="newAsset?.currency && newAsset?.agent_id" class="col-12 col-md-6">
                  <div class="form-group">
                    <label for="asset_id" class="form-label">{{
                        $t("purchases.payment_type")
                      }}</label>
                    <div class="">
                      <select class="form-select mb-2" v-model="selectedPaymentType" @change="startPaymentProcess()"
                              required id="">
                        <option v-for="paymentType in paymentTypes" :key="paymentType" :value="paymentType.id">
                          {{
                            $i18n.locale == "ar"
                                ? paymentType.name
                                : paymentType.name_en
                          }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div v-if="newAsset?.currency && newAsset?.agent_id" class="col-12 col-md-6">
                  <div class="form-group">
                    <label class="form-label">{{
                        $t("purchases.pay_date")
                      }}</label>
                    <div class="">
                      <input v-model="due_date" type="date" :disabled="is_du_time" id="" class="form form-control" />
                    </div>
                  </div>
                </div>
                <div class="">
              <div class="mt-3" v-if="!isOnCredit">
                <hr>
                <div>
                  <div class="d-flex justify-content-between align-items-center">
                    <div>
                      <label for="asset_id">{{
                          $t("purchases.chose_acccount")
                        }}</label>
                    </div>
                    <button type="button" @click="addPayAccounts.push({})" class="btn">
                      <span class="bx bxs-plus-circle float-end fa-2x" style="color: #2a3042 !important"></span>
                    </button>
                  </div>
                  <div v-for="(payAccount, aIndex) in addPayAccounts" :key="payAccount">
                    <div class="purchase-container d-flex">
<!--                          @item-select="selectSupplier"-->
                      <div class="row w-100">
                        <div class="col-12 col-md-5 mb-2 mb-md-1">
                          <AutoComplete
                              v-model="payAccount.cash_account"
                              :suggestions="matchedAccounts"

                              @complete="accountSearch($event.query)"
                              :complete-on-focus="true"
                              :optionLabel="showLabel"
                              style="color: #333 !;width:100%" />
                        </div>
                        <div class="col-12 col-md-5 mb-2 mb-md-1">
                          <currency-input :disabled="!payAccount.cash_account" v-model="payAccount.value" :max="payAccount?.cash_account?.balance"
                                          class="form-control mb-2 mx-1" :placeholder="$t('purchases.amount')" ></currency-input>
                        </div>
                        <div class="col-12 col-md-2 mb-2 mb-md-1">
                          <button type="button" role="button" @click="addPayAccounts.splice(aIndex, 1)"
                                  :disabled="addPayAccounts.length == 1" class="btn btn-danger">
                            <i class="bx bx-trash"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                   <h6>{{$t("purchases.total")}}: {{processCurrency(newAsset?.currency,totalAddPayAccounts,true)}}</h6>
                   <h6 v-if="selectedPaymentType==3">{{$t("purchases.remaining")}}: {{processCurrency(newAsset.currency,(parseFloat(newAsset?.initial_book_value) - totalAddPayAccounts),true)}}</h6>
                </div>

              </div>
              <hr>
              <!-- <div v-if="purchase_order.prepayment">
                {{$t("purchases.advance_to_supplier")}}: {{processCurrency(purchase_order.currency,purchase_order.prepayment,true)}}
              </div>
              <div v-if="purchase_order.previous_payable">
                {{$t("purchases.payable_to_supplier")}}: {{processCurrency(purchase_order.currency,purchase_order.previous_payable,true)}}
              </div> -->
            </div>
              </div>
            </div>
            <div class="modal-footer" :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'">
              <button :disabled="!newAsset?.initial_book_value || !validateFullPayment" type="submit" class="btn btn-primary">
                {{ $t("popups.add") }}
              </button>
              <button type="button" class="btn btn-danger">
                {{ $t("popups.cancel") }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!--End Add Modal-->

    <!--Start register Modal-->
    <div class="modal fade" id="registerModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true"
      :dir="$i18n.locale == 'ar' ? 'rtl' : ''">
      <div class="modal-dialog">
        <div class="modal-content">
          <form @submit.prevent="registerFixedAsset()">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                {{ $t("fixed_assets.register") }}
              </h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                style="margin: 0"></button>
            </div>

            <div class="modal-body">
              <div class="row">
                <div class="col-12 col-md-6">
                  <div class="form-group">
                    <label for="asset_id">{{ $t("fixed_assets.type") }}</label>

                    <select v-model="registerAsset.fixed_asset_type" @change="
                      selectFixedAssetTypeRegister(
                        registerAsset.fixed_asset_type
                      )
                      " class="form-select" id="account_id" :placeholder="$t('fixed_assets.type')">
                      <option v-for="options in typesOptions" :key="options" :value="options">
                        {{
                      $i18n.locale == "ar"
                        ? options?.name
                        : options?.name_en
                    }}
                      </option>
                    </select>
                  </div>
                </div>

                <div class="col-12 col-md-6 mb-2">
                  <div class="col">
                    <label for="asset_id">{{ $t("fixed_assets.label") }}</label>
                    <input v-model="registerAsset.name" type="text" class="form-control mb-2" />
                  </div>
                </div>
                <div class="col-12 col-md-6">
                  <div class="col">
                    <label for="asset_id">{{
                      $t("fixed_assets.label_en")
                    }}</label>
                    <input v-model="registerAsset.name_en" type="text" class="form-control mb-2" />
                  </div>
                </div>

                <div class="col-12 col-md-6">
                  <div class="col">
                    <label for="asset_id">{{
                      $t("fixed_assets.initial")
                    }}</label>
                    <currency-input v-model="registerAsset.initial_book_value" class="form-control mb-2" />
                  </div>
                </div>


                <div class="col-12 col-md-6" v-if="registerAsset?.fixed_asset_type?.depreciable">
                  <div class="col">
                    <label for="asset_id">{{
                      $t("fixed_assets.salvage_value")
                    }}</label>
                    <currency-input v-model="registerAsset.salvage_value" class="form-control mb-2" />
                  </div>
                </div>
                <div class="col-12 col-md-6" v-if="registerAsset?.fixed_asset_type?.depreciable">
                  <div class="col">
                    <label for="asset_id">{{
                      $t("fixed_assets.useful_life_years")
                    }}</label>
                    <input v-model="registerAsset.useful_life_years" type="number" class="form-control mb-2" />
                  </div>
                </div>

                <div class="col-12 col-md-6">
                  <div class="col">
                    <label for="asset_id">{{
                      $t("fixed_assets.installation_date")
                    }}</label>
                    <input v-model="registerAsset.installation_date" type="date" class="form-control mb-2" />
                  </div>
                </div>
                <div class="col-12 col-md-6 mb-2">
                  <div class="col form-check">
                    <input type="checkbox" v-model="registerAsset.under_warranty" id="under_warranty"
                      class="form-check-input" />
                    <label class="form-check-label" for="under_warranty">{{
                      $t("fixed_assets.under_warranty")
                    }}</label>
                  </div>
                </div>
                <div class="col-12 col-md-6 mb-2" v-if="registerAsset.under_warranty">
                  <div class="col">
                    <label for="asset_id">{{
                      $t("fixed_assets.warranty_exp")
                    }}</label>
                    <input v-model="registerAsset.warranty_exp" type="date" class="form-control mb-2" />
                  </div>
                </div>
                <div class="col-12 col-md-6 mb-2">
                  <div class="col">
                    <label for="asset_id">{{
                      $t("fixed_assets.maintenance_rating")
                    }}</label>
                    <input v-model="registerAsset.maintenance_rating" type="number" class="form-control mb-2" />
                  </div>
                </div>

                <div class="col-12 col-md-6 mb-2">
                  <div class="col">
                    <label for="asset_id">{{
                      $t("fixed_assets.comment")
                    }}</label>
                    <textarea v-model="registerAsset.comment" class="form-control mb-2"
                      :placeholder="$t('fixed_assets.comment')"></textarea>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer" :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'">
              <button type="submit" class="btn btn-primary">
                {{ $t("popups.add") }}
              </button>
              <button data-bs-dismiss="modal" type="button" class="btn btn-danger">
                {{ $t("popups.cancel") }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!--End register Modal-->

    <!--Start Edit Modal-->
    <div class="modal fade" id="editModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true"
      :dir="$i18n.locale == 'ar' ? 'rtl' : ''">
      <div class="modal-dialog">
        <div class="modal-content">
          <form @submit.prevent="editFixedAsset()">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                {{ $t("popups.edit") }}
              </h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                style="margin: 0"></button>
            </div>

            <div class="modal-body">
              <div class="row">
                <div class="col-12 col-md-6">
                  <div class="form-group">
                    <label for="asset_id">{{ $t("fixed_assets.label") }}</label>
                    <input required v-model="newAsset.name" type="text" class="form-control" />
                  </div>
                </div>
                <div class="col-12 col-md-6">
                  <div class="form-group">
                    <label for="asset_id">{{
                      $t("fixed_assets.label_en")
                    }}</label>
                    <input required v-model="newAsset.name_en" type="text" class="form-control" />
                  </div>
                </div>




                <div class="col-12 col-md-6" v-if="newAsset?.depreciable">
                  <div class="form-group">
                    <label for="asset_id">{{
                      $t("fixed_assets.salvage_value")
                    }}</label>
                    <currency-input v-model="newAsset.salvage_value" class="form-control" />
                  </div>
                </div>
                <div class="col-12 col-md-6" v-if="newAsset?.depreciable">
                  <div class="form-group">
                    <label for="asset_id">{{
                      $t("fixed_assets.useful_life_years")
                    }}</label>
                    <input required v-model="newAsset.useful_life_years" type="number" class="form-control" />
                  </div>
                </div>
                <!-- d -->
                <div class="col-12 col-md-6">
                  <div class="form-group">
                    <label for="asset_id">{{
                      $t("fixed_assets.installation_date")
                    }}</label>
                    <input required v-model="newAsset.installation_date" type="date" class="form-control" />
                  </div>
                </div>
                <div class="col-12 col-md-6">
                  <div class="form-group form-check">
                    <input type="checkbox" v-model="newAsset.under_warranty" id="under_warranty"
                      class="form-check-input" />
                    <label class="form-check-label" for="under_warranty">{{
                      $t("fixed_assets.under_warranty")
                    }}</label>
                  </div>
                </div>
                <div class="col-12 col-md-6" v-if="newAsset.under_warranty">
                  <div class="form-group">
                    <label for="asset_id">{{
                      $t("fixed_assets.warranty_exp")
                    }}</label>
                    <input v-model="newAsset.warranty_exp" type="date" class="form-control" />
                  </div>
                </div>

                <div class="col-12 col-md-6">
                  <div class="form-group">
                    <label for="asset_id">{{
                      $t("fixed_assets.comment")
                    }}</label>
                    <textarea v-model="newAsset.comment" class="form-control"></textarea>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer" :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'">
              <button type="submit" class="btn btn-primary">
                {{ $t("popups.edit") }}
              </button>
              <button data-bs-dismiss="modal" type="button" class="btn btn-danger">
                {{ $t("popups.cancel") }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!--End Edit Modal-->

    <!-- Dispose Modal -->
    <div class="modal fade" id="disposeModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true"
      :dir="$i18n.locale == 'ar' ? 'rtl' : ''">
      <div class="modal-dialog">
        <div class="modal-content">
          <form @submit.prevent="doDispose">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                {{
                      this.$i18n.locale == "ar" ? "القيمة الحالية" : "Current Value"
                    }}
                {{ parseFloat(remaining_value).toLocaleString() }}
              </h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                style="margin: 0"></button>
            </div>

            <div class="modal-body">
              <div class="row">
                <!-- Dispose Value -->
                <div class="col-12 col-md-6 mb-2">
                  <div class="col">
                    <label for="asset_id">{{
                      $t("fixed_assets.sell_value")
                    }}</label>
                    <currency-input v-model="disposed_asset.value" class="form-control mb-2" />
                  </div>
                </div>
                <div class="col-12 col-md-6">
                  <div class="col">
                    <label for="asset_id">{{
                      $t("fixed_assets.chose_account")
                    }}</label>
                  </div>
                  <div class="col d-flex justify-content-end">
                    <button v-if="addAsset.length < 3" type="button" @click="addAsset.push({})" class="btn float-end">
                      <vue-feather type="plus" stroke="black" />
                    </button>
                  </div>
                </div>
                <div v-for="(asset, addAssetIndex) in addAsset" :key="asset">
                  <div class="row my-3">
                    <div class="d-flex justify-content-between">
                      <select v-model="asset.level_three_chart_of_account_id" class="form-select mb-2" id="asset_id"
                        :placeholder="$t('fixed_assets.account_name')">
                        <option v-for="cashAccount in cashAccounts" :key="cashAccount.id" :value="cashAccount.id">
                          {{
                      $i18n.locale == "ar"
                        ? cashAccount?.name
                        : cashAccount?.name_en
                    }}
                          - ({{ cashAccount.balance.toLocaleString() }})
                        </option>
                      </select>
                      <currency-input v-model="asset.value" class="form-control mb-2 mx-1"
                        :placeholder="$t('journal.amount')" />
                      <span class="mx-2">
                        <button class="btn btn-danger" @click="addAsset.splice(addAssetIndex, 1)"
                          v-if="addAsset.length > 1">
                          <vue-feather type="trash" stroke="white" class="trash-icon-width" />
                        </button>
                        <button v-else disabled class="btn btn-danger">
                          <vue-feather type="trash" stroke="white" class="trash-icon-width" />
                        </button>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer" :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'">
              <button :disabled="disposed_asset.value != totalDisposalAccountsValue" type="submit"
                class="btn btn-primary">
                {{ $t("fixed_assets.disposal") }}
              </button>
              <button data-bs-dismiss="modal" type="button" class="btn btn-danger">
                {{ $t("popups.cancel") }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!-- Dispose Modal End -->
    <!-- reEvaluteModal Modal -->
    <div class="modal fade" id="reEvaluteModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true"
      :dir="$i18n.locale == 'ar' ? 'rtl' : ''">
      <div class="modal-dialog">
        <div class="modal-content">
          <form @submit.prevent="reEvaluteAsset">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                {{ $t("fixed_assets.re_Evalute_Asset") }}
              </h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                style="margin: 0"></button>
            </div>

            <div class="modal-body">
              <div class="row">
                <!-- reEvalute Value -->
                <div class="col-12 col-md-6 mb-2">
                  <div class="col">
                    <label for="asset_id">{{
                      $t("fixed_assets.re_Evalute_value")
                    }}</label>
                    <currency-input v-model="reEvaluate_value.value" class="form-control mb-2" />
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer" :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'">
              <button type="submit" class="btn btn-primary">
                {{ $t("fixed_assets.re_Evalute") }}
              </button>
              <button data-bs-dismiss="modal" type="button" class="btn btn-danger">
                {{ $t("popups.cancel") }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!-- reEvaluteModal Modal End -->

    <!--   Apps  Pagination     -->
    <div>
      <ul class="pagination pagination-rounded justify-content-center mb-2">
        <li class="page-item" :class="{ disabled: page == 1 }">
          <a class="page-link" href="javascript: void(0);" @click="getFixedAssetsPagination(page - 1)"
            aria-label="Previous">
            <i class="mdi mdi-chevron-left"></i>
          </a>
        </li>
        <li class="page-item" :class="{ active: p == page }" v-for="p in tot_pages" :key="p">
          <a class="page-link" href="javascript: void(0);" @click="getFixedAssetsPagination(p)">{{ p }}</a>
        </li>

        <li class="page-item" :class="{ disabled: page == tot_pages }">
          <a class="page-link" href="javascript: void(0);" @click="getFixedAssetsPagination(page + 1)"
            aria-label="Next">
            <i class="mdi mdi-chevron-right"></i>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<style scoped>
.purchase-container {
  background: #fafafa;
  margin: 1rem;
  border-radius: 0.5rem;
  padding: 1rem;
}

.balance-table tr td {
  vertical-align: middle;
}

.trash-icon-width {
  width: 24px
}
</style>
